/* nice black 191919 */

body {
  font-family: "Roboto";
  color: #000;
}

#root {
  background: #191919;
}

/*https://www.toptal.com/designers/subtlepatterns/tag/dark/page/3/*/
/* 
body::after {
  content: "";
  background-image: url("dust_background_pattern.png"); 
  background-repeat: repeat;
  top: 0;
  left: 0;
  opacity: 0.92;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
} */

@keyframes dopecolors {
  0% {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(196deg)
      brightness(118%) contrast(119%);
  }
  50% {
    filter: invert(48%) sepia(79%) saturate(76%) hue-rotate(16deg)
      brightness(118%) contrast(119%);
  }
  100% {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(296deg)
      brightness(118%) contrast(119%);
  }
}

#appBarMenu {
  background: #fff;
  color: #000;
}

/* #appBarMenuTitle {
  font-weight: 600;
} */

.language-option,
#appBarMenuLanguagesButton,
#appBarMenuLanguages {
  font-size: 0.95rem;
}

#appBarMenuLanguages {
  margin-top: 40px;
  margin-left: -13px;
}

#topNavWrapper {
  position: absolute;
  background: rgba(255, 0, 255, 0.2);
}

#dotArtProfile {
  /* height: 40vmin; */
  pointer-events: none;
  /* animation: dopecolors 10s ease-in-out 3; */
  animation: dopecolors 10s ease-in-out infinite;
}

#bottomNavWrapper {
  background: rgba(255, 0, 255, 0.2);
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 3px 4px rgba(0, 0, 0, 0.12),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.nav-label,
.nav-icon {
  color: #00ff00;
  font-weight: 700;
}

.wiggle-animation {
  animation: wiggle 0.5s ease-in-out 13;
}

@keyframes wiggle {
  30% {
    transform: rotate(-5deg);
    margin-bottom: 2.5em;
  }
  60% {
    transform: rotate(10deg);
  }
}

#appCenterContentWrapper {
  display: flex;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-center-content-item {
  /* width: 100px; */
  /* height: 100px; */
  /* background: #00ff00; */
}

#terminalWrapper {
  background: rgba(0, 0, 0, 0.95);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  color: #00ff00;
}

#terminalTopContainer {
  margin: 10px;
  /* padding: 10px; */
  border: 2px solid grey;
  /* background: #fff; */
  height: 50vh;
  border-radius: 5px;
}

#terminalTopBar {
  background: gray;
  padding: 3px;
}

#terminalTopBar .circle-button {
  width: 12px;
  height: 12px;
  /* margin: 10px 4px 0 0; */
  margin-right: 3px;
  display: inline-block;
  border-radius: 8px;
  background: #fff;
  /* border: 2px solid #fff; */
}

#terminalTopBar .circle-button:last-child {
  background: #00f000;
}

#terminalTopBar .circle-button:nth-child(2) {
  background: #ffff00;
}

#terminalTopBar .circle-button:first-child {
  background: #ff000f;
}

#terminalBody {
  /* background: #ff000f; */
  padding: 3px;
  display: flex;
}

.typewriter h1 {
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid #00ff00; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #00ff00;
  }
}

#contactFormWrapper {
  padding: 13px;
}

.contact-form-field {
  width: 100%;
}
